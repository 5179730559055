<template>
<div>
	<creatsuccess :msg="showtype" @recovery="recovery">
		<div class="add-discount">
			<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="150px" class="demo-ruleForm" style="margin-bottom:80px;">
				<div style="padding: 15px;background: #fff;">
					<div style="border-bottom: 1px solid #F0F2F5;padding-bottom: 15px;margin-bottom: 15px;color: #303133;">基本信息</div>
						<el-form-item label="活动名称：" prop="activeName">
							<el-input v-model="ruleForm.activeName" :disabled="activeState==3" placeholder="最多输入20个字" maxlength="20" style="width:200px"></el-input>
						</el-form-item>
						<el-form-item label="活动时间：" required>
							<el-form-item prop="StartTime" class="el-form-date-pick">
								<el-date-picker style="width:200px" :disabled="activeState==3||activeState==2" format="yyyy-MM-dd HH:mm:ss"
								value-format="yyyy-MM-dd HH:mm:ss" v-model="ruleForm.StartTime" type="datetime" :clearable="false" placeholder="开始时间"
								:picker-options='maxOptions'>
								</el-date-picker>
							</el-form-item>
							<span style="margin:0 5px;">~</span>
							<el-form-item prop="EndTime" class="el-form-date-pick">
								<el-date-picker style="width:200px" :disabled="activeState==3" format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss"
								v-model="ruleForm.EndTime" type="datetime" placeholder="结束时间" :picker-options='minOptions'>
								</el-date-picker>
							</el-form-item>
						</el-form-item>

						<el-form-item label="活动标签：" style="margin-bottom: 10px !important;">
							<el-input v-model="ruleForm.activeTag" :disabled="activeState==3" placeholder="最多输入10个字" maxlength="10" style="width:200px"></el-input>
							<active-tag></active-tag>
						</el-form-item>

					<el-form-item label="活动限购：">
						<el-radio :disabled="activeState==3" v-model="ruleForm.ActivityLimitType" label="0" @change='changeRadio1'>不限购</el-radio>
						<el-form-item prop="ActivityQuota" :inline-message='true'>
							<el-radio :disabled="activeState==3" v-model="ruleForm.ActivityLimitType" label="1">
								<span>限购</span>
								<el-input :disabled="activeState==3" v-model="ruleForm.ActivityQuota" style="width: 100px;margin: 0 5px;"
								onkeyup="(this.v=function(){this.value=this.value.replace(/[^0-9]+/,'');}).call(this);if (value==0||value=='-'){value =''}"
								onblur="this.v();if (value==0||value=='-'){value =''}"></el-input>
								<span>件/人</span>
							</el-radio>
						</el-form-item>
						<div style="color: #909399;">本场活动每个用户所有商品限购数量</div>
					</el-form-item>
					<el-form-item label="商品限购：">
						<el-radio :disabled="activeState==3" v-model="ruleForm.radioLimit" label="0" @change='changeRadio'>不限购</el-radio>
						<el-form-item prop="limitNumber" :inline-message='true'>
							<el-radio :disabled="activeState==3" v-model="ruleForm.radioLimit" label="1">
								<span>限购</span>
								<el-input :disabled="activeState==3" v-model="ruleForm.limitNumber" style="width: 100px;margin: 0 5px;" onkeyup="(this.v=function(){this.value=this.value.replace(/[^0-9]+/,'');}).call(this);if (value==0||value=='-'){value =''}"
								onblur="this.v();if (value==0||value=='-'){value =''}"></el-input>
								<span>件/人</span>
							</el-radio>
						</el-form-item>
						<div style="color: #909399;">活动期间每个用户每种商品限购数量</div>
					</el-form-item>




					<el-form-item label="优惠券叠加：" required prop="IsSuperpositionCoupon">
						<div>
							<el-radio @change='changeSuperimposedRadio' :disabled="activeState==3" v-model="ruleForm.IsSuperpositionCoupon"
							:label="true">叠加</el-radio>
						</div>
						<div>
							<el-radio @change='changeSuperimposedRadio' :disabled="activeState==3" v-model="ruleForm.IsSuperpositionCoupon"
							:label="false">不叠加</el-radio>
						</div>
						<span style="color: #909399;">开启优惠券叠加后，本场活动商品可同时享有限时折扣、优惠券优惠</span>
					</el-form-item>

				</div>

				<div style="background: #fff;padding: 15px;margin-top: 10px">
					<div style="border-bottom: 1px solid #F0F2F5;padding-bottom: 15px;color: #303133;">活动信息</div>
					<el-button type="text" class='is-error-button' style="margin:10px 10px 10px 0;" @click="choseActive">选择活动商品</el-button>
					<el-input v-model="productSearchKey" placeholder="搜索已选商品名称、编码" style="width:260px;"  @keyup.enter.native="handleFilterSelectes">
						 <el-button slot="append" icon="el-icon-search" @click='handleFilterSelectes'></el-button>
					</el-input>
					<div style='margin-bottom:10px;'>
						<span style="font-size: 14px;margin-right:10px;">
							共
							<span v-if="installationLength" style="color:red;" >{{installationLength}}</span>
							<span v-else>{{installationLength}}</span>
							个商品折扣设置错误
						</span>
						<el-select v-model="discountSetType" @change="changeDiscountSetType" placeholder="请选择"  style="width: 188px;margin-right: 10px;">
							<el-option  label="全部" :value="false"></el-option>
							<el-option  label="显示错误商品" :value="true"></el-option>
						</el-select>
					</div>
					<div class="table-container" :class="{'is-error-button':installationLength}" style="min-height: 30px;margin-bottom: 50px;">
						<el-table :data="ruleForm.ProductList" style="width: 100%;" ref="productChose">
							<el-table-column label="商品" width="500">
								<template slot-scope="scope">
									<div class="product-info">
										<img v-if="scope.row.ImgUrlComplete" :src="scope.row.ImgUrlComplete">
										<svg-icon v-else icon-class="noImgIcon" />
										<div>
											<div>
												<pre style="white-space: pre-wrap;margin:0;font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Arial, sans-serif;">{{scope.row.Name}}</pre>
											</div>
											<span v-if="!scope.row.IsOpen" style="color: #E51C23;">已下架</span>
											<span v-if="scope.row.Stock<1" style="color: #E51C23;">已售罄</span>
										</div>
									</div>
								</template>
							</el-table-column>
							<el-table-column prop="ProductPrice" label="价格"></el-table-column>
							<el-table-column prop="Stock" label="库存"></el-table-column>
							<el-table-column prop="Discount" label="折扣" width="660px">
								<template slot-scope="scope">
									<div class="discount-container">
										<div class="discount-item" >
											<span style="margin-right: 5px;">打</span>
											<el-form-item inline-message :prop="'ProductList.' +scope.$index + '.Discount'"	:rules='rules.Discount' style="display:inline-block;margin:20px 0;height:50px;" label-width='0'>
												<el-input 
													v-model="scope.row.Discount" 
													:disabled="activeState==3" 
													size="mini" 
													class="input-with-select"
													:class="{ activepromotionMethod: scope.row.promotionMethod==2}"
													style="width: 100px;margin: 0 5px;"
													@change.native="changeDiscount(scope.row,scope.$index)"></el-input>
											</el-form-item>
											<span style="margin-left: 5px;">折</span>
										</div>
										<span style='margin:0 5px;'>，</span>
										<div class="discount-item" >
											<span style="margin-right: 5px;">减金额</span>
											<el-form-item inline-message :prop="'ProductList.' +scope.$index + '.DiscountPrice'"	:rules='rules.DiscountPrice' style="display:inline-block;margin:20px 0;height:50px;" label-width='0'>
												<el-input 
												v-model="scope.row.DiscountPrice"
												:disabled="activeState==3" 
												size="mini" 
												class="input-with-select" 
												:class="{ activepromotionMethod: scope.row.promotionMethod==3}"
												style="width: 120px;margin: 0 5px;" 
												@change.native="changeDiscountPrice(scope.row,scope.$index)" 
												></el-input>
											</el-form-item>
											<span style="margin-left: 5px;">元</span>
										</div>
										<span style='margin:0 5px;'>，</span>
										<div class="discount-item" >
											<span style="margin-right: 5px;">活动价</span>
											<el-form-item inline-message :prop="'ProductList.' +scope.$index + '.SpecifiedPrice'"	:rules='rules.SpecifiedPrice' style="display:inline-block;margin:20px 0;height:50px;" label-width='0'>
												<el-input 
												v-model="scope.row.SpecifiedPrice"
												:disabled="activeState==3" 
												size="mini" 
												class="input-with-select" 
												style="width: 120px;margin: 0 5px;" 
												:class="{ activepromotionMethod: scope.row.promotionMethod==4}"
												@change.native="changeSpecifiedPrice(scope.row,scope.$index)" 
												></el-input>
											</el-form-item>
											<span style="margin-left: 5px;">元</span>
										</div>
									</div>
								</template>
							</el-table-column>
							<el-table-column prop='promotionMethod' label="优惠方式" width='90px'>
								<template slot-scope="scope">
									<span v-if="scope.row.promotionMethod==1">--</span>
									<div v-if="scope.row.promotionMethod!=1" @click='handleShowTooltip(scope.row)' style="color:#409EFF;cursor:pointer;">
										<span v-if="scope.row.promotionMethod==2">打折</span>
										<span v-if="scope.row.promotionMethod==3">减金额</span>
										<span v-if="scope.row.promotionMethod==4">活动价</span>
									</div>
								</template>
							</el-table-column>
							<el-table-column label="操作" width="120" v-if="activeState!=3">
								<template slot-scope="scope">
									<span style="color:#C0C4CC;" v-if="activeState==3">删除</span>
									<span style="color:#F56C6C;cursor:pointer;" v-else @click="deleteChose(scope.row,scope.$index)">删除</span>
								</template>
							</el-table-column>
						</el-table>
						<div style="display:flex;justify-content:space-between;align-items:center;">
							<div style="display:flex;font-size: 14px;color: #606266;align-items:center;margin-left: 13px;padding-top: 20px;"
							v-if="activeState!=3&&selectedList.length">
								批量设置：
								<div style="display: flex;" v-if="!isShowEdit">
									<div style="color: #409EFF;cursor:pointer;" @click="commonDiscountClick">折扣</div>
									<div style="color: #409EFF;margin:0 10px;cursor:pointer;" @click="commonDiscountPirceClick">减金额</div>
									<div style="color: #409EFF;cursor:pointer;" @click="commonSpecifiedPriceClick">活动价</div>
								</div>
								<!-- 批量折扣 -->
								<div v-if="isShowEdit&&currentType==0" style="display: flex;align-items: center;">
									<el-input v-model="commonDiscount" size="mini" style="margin-left: 5px;" @change="changeCommonDiscount(commonDiscount)"></el-input>
									<span style="margin-left: 10px;white-space: nowrap;cursor:pointer;" @click="editDiscountOk">确定</span>
									<span style="margin-left: 10px;white-space: nowrap;cursor:pointer;" @click="editCancel">取消</span>
								</div>
								<!-- 批量减金额 -->
								<div v-if="isShowEdit&&currentType==1" style="display: flex;align-items: center;">
									<el-input v-model="commonDiscountPrice" size="mini" style="margin-left: 5px;"	@change="changeCommonDiscountPrice(commonDiscountPrice)"></el-input>
									<span style="margin-left: 10px;white-space: nowrap;cursor:pointer;" @click="editDiscountPriceOk">确定</span>
									<span style="margin-left: 10px;white-space: nowrap;cursor:pointer;" @click="editCancel">取消</span>
								</div>
								<!-- 批量活动价 -->
								<div v-if="isShowEdit&&currentType==2" style="display: flex;align-items: center;">
									<el-input v-model="commonSpecifiedPrice" size="mini"	style="margin-left: 5px;"	@change="changeCommonSpecifiedPrice(commonSpecifiedPrice)"></el-input>
									<span style="margin-left: 10px;white-space: nowrap;cursor:pointer;" @click="editSpecifiedPriceOk">确定</span>
									<span style="margin-left: 10px;white-space: nowrap;cursor:pointer;" @click="editCancel">取消</span>
								</div>
							</div>
							<div v-else></div>
							<el-pagination v-if="page.total" style="margin-top:20px;float:right;" @size-change="productSizeChange"
							@current-change="productCurrentChange" :current-page="page.current" :page-sizes="[5, 10, 15, 20, 25]" :page-size="page.size"
							layout="total, sizes, prev, pager, next, jumper" :total="page.total">
							</el-pagination>
						</div>
					</div>
				</div>
				<div style="background: #fff;padding: 15px;margin-top: 10px">
					<div style="border-bottom: 1px solid #F0F2F5;padding-bottom: 15px;color: #303133;">其他配置</div>
					<el-form-item label="活动预热：" class="margin-top-20">
						<el-radio-group v-model="ruleForm.ActivityPreheatType" :disabled="activeState!=1">
							<div class="flex flex-align-center margin-top-10">
								<el-radio :label="0">不预热</el-radio>
							</div>
							<div class="flex flex-align-center margin-top-20">
								<el-radio :label="1">活动创建成功就开启预热</el-radio>
							</div>
							<div class="flex flex-align-center margin-top-20">
								<el-radio :label="2" class="flex flex-align-center">
									<div class="flex flex-align-center">
										<span class="margin-right-10">活动开始前</span>
										<el-form-item label-width="0px" style="width:100%" prop="ActivityPreheatHours">
											<el-input class="margin-right-10" style="width:120px" placeholder="" v-model="ruleForm.ActivityPreheatHours"
												oninput="value=((value.replace(/[^0-9.]/g,'')).replace(/([0-9]+\.[0-9]{1})[0-9]*/,'$1'))"
												:disabled="ruleForm.ActivityPreheatType!=2"></el-input>
											小时开启预热
										</el-form-item>
									</div>
								</el-radio>
							</div>
						</el-radio-group>
					</el-form-item>
				</div>
			</el-form>
			
			<el-dialog title="选择商品" :visible.sync="addProductDialogShow" width="1300px" class="dialog">
				<select-produce api="discountchoseproductlist" :params="['KeyWords', 'ProductType', 'ProductGroupId', 'ProductBrandId', 'IsSelectable','ActivityFullId']"
				:selectedData="selectedList" :couponData="timeData"  :ActivityFullId="Number(activityId)" @getSelectList="getSelectList" :visible.sync="addProductDialogShow"
				v-if="addProductDialogShow">
				</select-produce>
			</el-dialog>

			<!-- 冲突商品列表 -->
			<el-dialog :visible.sync="conflictVisible" width="1000px;" title="以下商品与其它活动冲突,请重新设置" center>
				<el-table :data="conflictList" style="max-height:450px;overflow: auto;">
					<el-table-column prop="ProductName" label="商品"></el-table-column>
					<el-table-column prop="Remark" label="冲突信息"></el-table-column>
				</el-table>
			</el-dialog>

			<div class="footer" >
				<el-button style="width: 240px;" @click="handleCancel">取消</el-button>
				<el-button style="width: 240px;margin-left: 40px;" type="primary" v-if="activeState!=3" @click="saveBtn" :loading="loading">保存</el-button>
			</div>

			<el-dialog title="优惠明细" width="700px" :visible.sync="tooltipTableVisible">
				<div style="margin-bottom:10px;font-size: 16px;">
					商品：
					{{tooltipTableName}}
				</div>
					<el-table :data="tooltipTableData" max-height="600" v-loading ='tooltipLoading'>
						<el-table-column	label="规格名">
							<template slot-scope="scope">
								<span>{{scope.row.SpecTitle}}</span>
								<br>
								<span>{{scope.row.SpecTitle2}}</span>
							</template>
						</el-table-column>
						<el-table-column  label="规格值">
							<template slot-scope="scope">
								<span>{{scope.row.SpecValue}}</span>
								<br>
								<span>{{scope.row.SpecValue2}}</span>
							</template>
						</el-table-column>
						<el-table-column  label="原价"	prop="Price"></el-table-column>
						<el-table-column  label="活动价"	prop="ActivePrice"></el-table-column>
					</el-table>
			</el-dialog>
		</div>
	</creatsuccess>
</div>
</template>

<script>
	import {
		timelimitdiscountdetail,
		discountchoseproductlist,
		timelimitdiscountedit
	} from '@/api/goods'
	 import apiList from '@/api/other'
	import selectProduce from '@/components/SelectMulProduce/SelectProduceTime';
	import activeTag from './components/activeTag'
	import creatsuccess from "@/components/creatsuccess/creatsuccess.vue"
	export default {
		components: {
			selectProduce,
			activeTag,
			creatsuccess
		},
		data() {
			var checkDiscount = (rule, value, callback) => {
					let index1 =  rule.fullField.split(".")[1];
					let index = (this.page.current-1)* (this.page.size) + Number(index1)
				if (this.selectedFilterList[index].promotionMethod == 2){
					let value1 =  this.selectedFilterList[index].Discount
					
					if (Number(value1)>9.9||Number(value1)<0.1){
						return callback(new Error('0.1≦折扣≦9.9，最多1位小数'));
					} else if(Number(value1)==0||Number(value1)<0||value1==''||value1==null){
						return callback(new Error('0.1≦折扣≦9.9，最多1位小数'));
					}else {
						return callback()
					}
				}else{
					return callback()
				}
			};
			var checkDiscountPrice = (rule, value, callback) => {
				
					let index1 =  rule.fullField.split(".")[1];
					let index = (this.page.current-1)* (this.page.size) + Number(index1)
				if (this.selectedFilterList[index].promotionMethod == 3){
					let priceMin = this.selectedFilterList[index].ProductPrice
					let value1 =  this.selectedFilterList[index].DiscountPrice
					if (value1>priceMin||value1==priceMin){
						return callback(new Error('减免金额不可超过规格最低价'));
					} else if(value1==0||value1=='0'||Number(value1)<0||value1==''){
						return callback(new Error('减金额需大于0，最多2位小数'));
					}else {
						return callback()
					}
				}else{
					return callback()
				}
				
			};
			var checkSpecifiedPrice= (rule, value, callback) => {
				let index1 =  rule.fullField.split(".")[1];
					let index = (this.page.current-1)* (this.page.size) + Number(index1)
				if (this.selectedFilterList[index].promotionMethod == 4){
					let priceMin = this.selectedFilterList[index].ProductPrice
					let value1 =  this.selectedFilterList[index].SpecifiedPrice
					if ((value1>priceMin||value1==priceMin)&&value1!=0) {
						return callback(new Error('活动价不可超过规格最低价'));
					} else if(value1==0||value1=='0'||Number(value1)<0||value1==''){
						return callback(new Error('活动价需大于0，最多2位小数'));
					}else {
						return callback()
					}
				}else{
					return callback()
				}
				
			};
			var checkActivityName = (rule, value, callback) => {
				if (!value) {
					return callback(new Error('请输入活动名称'));
				} else if (!value.trim()) {
					return callback(new Error('请输入活动名称'));
				} else {
					return callback()
				}
			};
			var checkIsSuperpositionCoupon = (rule, value, callback) => {
				if (value===''||value==null) {
					return callback(new Error('请配置优惠券叠加方案'));
				} else {
					return callback()
				}
			};
			var checkno = (rule, value, callback) => {
				return callback();
			};
			var checklimitNumber = (rule, value, callback) => {
				if ((this.ruleForm.limitNumber == 0 || !this.ruleForm.limitNumber)&&this.ruleForm.radioLimit==1) {
					return callback(new Error('请输入限购件数'));
				} else {
					return callback()
				}
			};
			var checkActivityQuota = (rule, value, callback) => {
				if ((this.ruleForm.ActivityQuota == 0 || !this.ruleForm.ActivityQuota) && this.ruleForm.ActivityLimitType == 1) {
					return callback(new Error('请输入限购件数'));
				} else {
					return callback()
				}
			};
			var checkActivityPreheatHours = (rule, value, callback) => {
				if(this.ruleForm.ActivityPreheatType!=2){
					callback()
				}
				let one = /^\d+(\.\d{1,1})?$/
				if(value < 0.5 || value >360 || !one.test(value)){
					callback(new Error('时间请设置在0.5~360小时之间，1位小数'))
				}
				callback()
			};
			return {
				tooltipLoading:false,
				currentIndex:0,
				loading: false,
				ruleForm: {
					activeName: '',
					activeTag: '',
					radioLimit: '0',
					limitNumber: '',
					IsSuperpositionCoupon:'',
					StartTime: '',
					EndTime: '',
					ActivityLimitType: '0',
					ActivityQuota: '',
					ActivityPreheatType:0,
					ActivityPreheatHours:'',
				},
				rules: {
					Discount: [{
						required: true,
						trigger: 'change',
						validator: checkDiscount
					}],
					DiscountPrice: [{
						required: true,
						trigger: 'change',
						validator: checkDiscountPrice
					}],
					SpecifiedPrice: [{
						required: true,
						trigger: 'change',
						validator: checkSpecifiedPrice
					}],
					StartTime: [{
						required: true,
						message: '请选择开始日期',
						trigger: 'change'
					}],
					EndTime: [{
						required: true,
						message: '请选择结束日期',
						trigger: 'change'
					}],
					activeName: [{
							required: true,
							trigger: 'blur',
							validator: checkActivityName
						},
						{
							min: 1,
							max: 20,
							message: '最多输入20个字',
							trigger: 'blur'
						}
					],
					IsSuperpositionCoupon: [{
						required: true,
						validator: checkIsSuperpositionCoupon,
						trigger: 'change'
					}],
					limitNumber: [{
						validator: checklimitNumber,
						trigger: 'blur'
					}],
					ActivityQuota: [{
						validator: checkActivityQuota,
						trigger: 'blur'
					}],
					ActivityPreheatHours:[{
						validator: checkActivityPreheatHours,
						trigger: 'blur'
					}]
				},
				productSearchKey:'',
				installationLength:0,
				discountSetType:false,
				selectedFilterList:[],
				productData: [],
				selectedList: [],
				paginationList: [],
				choseData: [],
				page: {
					total: 0,
					size: 5,
					current: 1
				},
				commonDiscount: '',
				commonDiscountPrice:'',
				commonSpecifiedPrice: '',
				isShowEdit: false, //是否显示批量编辑输入框
				currentType: 0, //0为批量打折,1为批量折后价
				checkedAll: false, //当前页全选
				checked: true, //是否 仅看可选商品
				addProductDialogShow: false,
				currentPage1: 1,
				pageSize1: 5,
				Total1: 0,
				roleList: [],
				searchKey: '',
				saleType: '', 
				goodsType: '',
				brandType: '',
				activityId: 0,
				onlyLook: 0,
				isCopy: 0,
				conflictVisible: false, //是否显示冲突tank
				conflictList: [], //冲突信息列表
				activeState: 1, //判断活动是否开始，false：未开始
				timeData: {
					StartTime: '',
					EndTime: ''
				},
				tooltipTableName:'',
				tooltipTableData:[],
				tooltipTableVisible:false,

				showtype:{
					issuccess:false,
					id:0,
					type:0,
					suctype:3,
				}
			};
		},
		computed: {
			//日期选择器限制选择
			minOptions: function() {
				let limitTime = this.ruleForm.StartTime
				let d = new Date();
				let day = d.getDate();
				let month = d.getMonth() + 1;
				let year = d.getFullYear();
				let currentDate = year + '-' + month + '-' + day + ' ' + '00:00:00'
				return {
					disabledDate(time) {
						if (limitTime) {
							return time < new Date(new Date(limitTime).toLocaleDateString())
						} else {
							return time < new Date(currentDate)
						}
					},
				}
			},
			//日期选择器限制选择
			maxOptions: function() {
				let limitTime = this.ruleForm.EndTime
				let d = new Date();
				let day = d.getDate();
				let month = d.getMonth() + 1;
				let year = d.getFullYear();
				let currentDate = year + '-' + month + '-' + day + ' ' + '00:00:00'
				return {
					disabledDate(time) {
						if (limitTime) {
							return (
								time > new Date(new Date(limitTime).toLocaleDateString()) ||
								time < new Date(currentDate)
							)
						} else {
							return time < new Date(currentDate)
						}
					}
				}
			}
		},
		beforeMount() {
			this.isCopy = this.$route.query.copy ? true : false;
			this.onlyLook = this.$route.query.onlylook ? true : false;
			this.activeState = this.$route.query.activeState ? this.$route.query.activeState : 1;
			if (this.$route.query.id) {
				this.activityId = this.$route.query.id;
				this.getData();

				if (this.isCopy) {
					this.activityId = 0;
				}
			}
		},
		methods: {
			recovery(){
				this.$router.push({path:this.$route.path})
				this.showtype.issuccess = false
				this.ruleForm = {
					activeName: '',
					activeTag: '',
					radioLimit: '0',
					limitNumber: '',
					IsSuperpositionCoupon:'',
					StartTime: '',
					EndTime: '',
					ActivityLimitType: '0',
					ActivityQuota: '',
					ActivityPreheatType:0,
					ActivityPreheatHours:''
				}
			},
			changeRadio(){
				this.$refs['ruleForm'].validateField('limitNumber')
			},
			changeRadio1() {
				this.$refs['ruleForm'].validateField('ActivityQuota')
			},
			changeCommonDiscount(value) {
				value = (value.match(/^\d*(\.?\d{0,1})/g)[0]) || null
				if (Number(value) > 9.9) {
					value = null
				} else if (Number(value) < 0.1) {
					value = null
				}
				this.commonDiscount = value
			},
			
			changeCommonDiscountPrice(value) {
				value =(value.match(/^\d*(\.?\d{0,2})/g)[0]) || null
				this.commonDiscountPrice = value
			},
			changeCommonSpecifiedPrice(value) {
				value =(value.match(/^\d*(\.?\d{0,2})/g)[0]) || null
				this.commonSpecifiedPrice = value
			},
			//设置打折
			changeDiscount(value) {
				let Id = value.Id
				value.Discount =((value.Discount).match(/^\d*(\.?\d{0,1})/g)[0]) || null
				if ( Number(value.Discount) > 9.9) {
					value.Discount = null
				} else if (Number(value.Discount) < 0.1) {
					value.Discount = null
				}

				if(Number(value.Discount)>0){
					this.selectedList.forEach(t=>{
						if(t.Id == Id){
							t.promotionMethod = 2
							t.Discount =  Number(value.Discount)

							let result = this.computedThreeDiscounts(t.promotionMethod,t, Number(value.Discount))
							t.DiscountPrice = result.DiscountPrice
							t.SpecifiedPrice = result.SpecifiedPrice
						}
					})
				}else{
					this.selectedList.forEach(t=>{
						if(t.Id == Id){
							value.Discount = null
							t.DiscountPrice = 0
							t.SpecifiedPrice = t.ProductPrice==t.ProductMaxPrice? t.ProductPrice: (t.ProductPrice + '-' + t.ProductMaxPrice)
							t.promotionMethod = 2
						}
					})
				}
				
				this.$forceUpdate()
				this.handleSingleSetting()
				this.$refs['ruleForm'].validateField('Discount')
			},
			changeSuperimposedRadio(){
				this.$refs['ruleForm'].validateField('IsSuperpositionCoupon')
			},
			//设置减金额
			changeDiscountPrice(value) {
				let Id = value.Id

				value.DiscountPrice =((value.DiscountPrice).match(/^\d*(\.?\d{0,2})/g)[0]) || null

				this.selectedList.forEach(t=>{
					if(t.Id == Id){
						t.promotionMethod = 3
						t.DiscountPrice = Number(value.DiscountPrice)

						let result = this.computedThreeDiscounts(t.promotionMethod,t, Number(value.DiscountPrice))
						t.Discount = result.Discount
						t.SpecifiedPrice = result.SpecifiedPrice
					}
				})
				this.editCancel()
				this.$forceUpdate()
				this.handleSingleSetting()
				this.$refs['ruleForm'].validateField('DiscountPrice')
			},
			//设置促销价
			changeSpecifiedPrice(value) {

				let Id = value.Id

				value.SpecifiedPrice =((value.SpecifiedPrice).match(/^\d*(\.?\d{0,2})/g)[0]) || null

				this.selectedList.forEach(t=>{
					if(t.Id == Id){
						t.promotionMethod = 4
						t.SpecifiedPrice = Number(value.SpecifiedPrice)

						let result = this.computedThreeDiscounts(t.promotionMethod,t,Number(value.SpecifiedPrice))
						t.Discount = result.Discount
						t.DiscountPrice = result.DiscountPrice
					}
				})

				this.editCancel()
				this.$forceUpdate()
				this.handleSingleSetting()
				this.$refs['ruleForm'].validateField('SpecifiedPrice')
			},
			
			handleSingleSetting(){
				let currentLength = this.selectedFilterList.length
				this.selectedFilterList = []
				this.selectedList.filter(t=>{
					if((t.Name.indexOf(this.productSearchKey)!=-1)||(t.ProductNo && (t.ProductNo.indexOf(this.productSearchKey)!=-1))){
						if(this.discountSetType){

							if(t.promotionMethod == 1){
								this.selectedFilterList.push(t)
							}else if(t.promotionMethod == 2){
								if(t.ProductPrice!=0&&(Number(t.Discount)<0||Number(t.Discount)==0||t.Discount==''||t.Discount==null)){
									this.selectedFilterList.push(t)
								}
							}else if(t.promotionMethod == 3){
								if(t.ProductPrice!=0&&(t.ProductPrice==t.DiscountPrice||t.DiscountPrice>t.ProductPrice||t.DiscountPrice==0||t.DiscountPrice<0)){
									this.selectedFilterList.push(t)
								}
							}else if(t.promotionMethod == 4){
								if(t.ProductPrice!=0&&(t.ProductPrice==t.SpecifiedPrice||t.SpecifiedPrice>t.ProductPrice||t.SpecifiedPrice==0||t.SpecifiedPrice<0)){
									this.selectedFilterList.push(t)
								}
							}

						}else{
							this.selectedFilterList.push(t)
						}
					}
				})

				if(this.selectedFilterList.length<currentLength){
					if ((this.selectedFilterList.length) % this.page.size == 0 && this.page.current > 1) {
						this.page.current -= 1;
					}
				}

				this.page.total = this.selectedFilterList.length;
				this.installationLength = 0
				this.computedInstallationLength()

				let start = (this.page.current - 1) * this.page.size
				let end = start + this.page.size
				let pagination = this.selectedFilterList.slice(start, end);
				this.ruleForm.ProductList = pagination
				this.$forceUpdate()
			},
			//批量设置打折
			editDiscountOk() {
				if(Number(this.commonDiscount)==0||Number(this.commonDiscount)<0||this.commonDiscount==null){
					this.selectedFilterList.forEach(t=>{
							t.promotionMethod = 2
							
							t.Discount = null;

							t.DiscountPrice = 0
							t.SpecifiedPrice = t.ProductPrice==t.ProductMaxPrice ? t.ProductPrice : (t.ProductPrice + '-' + t.ProductMaxPrice)
					})
				}else{
					this.selectedFilterList.forEach(t=>{
						t.promotionMethod = 2
							
						t.Discount = Number(this.commonDiscount);
						let result = this.computedThreeDiscounts(t.promotionMethod,t,Number(this.commonDiscount))

						t.DiscountPrice = result.DiscountPrice
						t.SpecifiedPrice = result.SpecifiedPrice
					})
				}
				this.editCancel()
				this.handleFilterSelectes()
				this.$refs['ruleForm'].validateField('Discount')
			},
			//批量设置优惠金额
			editDiscountPriceOk(){
				this.selectedFilterList.forEach(t=>{
					t.promotionMethod = 3
					
					t.DiscountPrice = Number(this.commonDiscountPrice);
					
					let result = this.computedThreeDiscounts(t.promotionMethod,t,Number(this.commonDiscountPrice))
					t.Discount = result.Discount
					t.SpecifiedPrice = result.SpecifiedPrice

					this.handleFilterSelectes();
					this.$refs['ruleForm'].validateField('DiscountPrice')
				})
				this.editCancel()
				this.handleFilterSelectes();
				this.$refs['ruleForm'].validateField('DiscountPrice')
			},
			
			//批量设置活动价
			editSpecifiedPriceOk(){
				this.selectedFilterList.forEach(t=>{
					t.promotionMethod = 4
					t.SpecifiedPrice = Number(this.commonSpecifiedPrice);

					let result = this.computedThreeDiscounts(t.promotionMethod,t,this.commonSpecifiedPrice)
					t.Discount = result.Discount
					t.DiscountPrice = result.DiscountPrice
				})
				this.editCancel()
				this.handleFilterSelectes();
				this.$refs['ruleForm'].validateField('SpecifiedPrice')
			},
			async getData() {
				this.loading = true
				const res = await timelimitdiscountdetail({
					ActivityFullId: this.activityId
				})

				if (res.IsSuccess) {
					const obj = res.Result;
					this.ruleForm = {
						activeName: obj.Name,
						activeTag: obj.ActivityFlag,
						radioLimit: obj.LimitType + '',
						limitNumber: obj.Quota,
						IsSuperpositionCoupon: obj.IsSuperpositionCoupon,
						StartTime: this.isCopy ? '' : obj.StartTime,
						EndTime: this.isCopy ? '' : obj.EndTime,
						ActivityLimitType: obj.ActivityLimitType  + '',
						ActivityQuota: obj.ActivityQuota,
						ActivityPreheatType:obj.ActivityPreheatType,
						ActivityPreheatHours:obj.ActivityPreheatHours||'',
					}
					
					this.selectedList = res.Result.TimeLimitDiscountDetailProductList.map(t=>{

						let promotionMethod = 1
						
						if(t.Discount){
							promotionMethod = 2

							let result = this.computedThreeDiscounts(2,t,t.Discount)

							return {
								...t,
								DiscountPrice : result.DiscountPrice,
								SpecifiedPrice : result.SpecifiedPrice,
								promotionMethod : promotionMethod,
							}
						}else if(t.DiscountPrice){
							promotionMethod = 3

							let result = this.computedThreeDiscounts(3,t,t.DiscountPrice)

							return {
								...t,
								Discount : result.Discount,
								SpecifiedPrice : result.SpecifiedPrice,
								promotionMethod : promotionMethod
							}
						}else if(t.SpecifiedPrice){
							promotionMethod = 4

							let result = this.computedThreeDiscounts(4,t,t.SpecifiedPrice)

							return {
								...t,
								Discount : result.Discount,
								DiscountPrice : result.DiscountPrice,
								promotionMethod : promotionMethod
							}
						}
						
					})

					this.selectedFilterList = this.selectedList

					this.paginationPro()
					this.$nextTick(()=>{
						this.$refs.ruleForm.validate();
					})
				}
				this.loading = false

			},
			computedThreeDiscounts(promotionMethod,item,settings){
				let discount = 0
				let discountMin = 0
				let discountMax = 0

				let discountPrice = 0
				let discountPriceMin = 0
				let discountPriceMax = 0

				let specifiedPrice = 0
				let specifiedPriceMin = 0
				let specifiedPriceMax = 0
				if(promotionMethod == 1){
					discount= null
					discountPrice= null
					specifiedPrice = null
				}else if(promotionMethod == 2){
					// 设置的值是打折
					discount = settings

					specifiedPriceMin = (settings*item.ProductPrice/10).toFixed(2)
					specifiedPriceMax = (settings*item.ProductMaxPrice/10).toFixed(2)

					discountPriceMin = (item.ProductPrice-settings*item.ProductPrice/10).toFixed(2)
					discountPriceMax = (item.ProductMaxPrice-settings*item.ProductMaxPrice/10).toFixed(2)

					
					if(item.ProductPrice == item.ProductMaxPrice){
						discountPrice = discountPriceMin
						specifiedPrice  = specifiedPriceMin
					}else{
						discountPrice  = (discountPriceMin==specifiedPriceMax)?discountPriceMin:(discountPriceMin + '-' + discountPriceMax)
						specifiedPrice  = (specifiedPriceMin==specifiedPriceMax)?specifiedPriceMin:(specifiedPriceMin + '-' + specifiedPriceMax)
					}
				}else if(promotionMethod == 3){
					// 设置的值是减金额
					discountPrice = settings
					
					specifiedPriceMin =  (item.ProductPrice-settings).toFixed(2)
					specifiedPriceMax = (item.ProductMaxPrice-settings).toFixed(2)

					discountMin = item.ProductPrice==0?0:((item.ProductPrice-settings)/item.ProductPrice*10).toFixed(1)
					discountMax = item.ProductMaxPrice==0?0:((item.ProductMaxPrice-settings)/item.ProductMaxPrice*10).toFixed(1)


					if(item.ProductPrice == item.ProductMaxPrice){
						specifiedPrice  = specifiedPriceMin
						discount  = discountMin
					}else{
						specifiedPrice  = (specifiedPriceMin==specifiedPriceMax) ? specifiedPriceMin : (specifiedPriceMin + '-' + specifiedPriceMax)
						discount  = (discountMin==discountMax) ? discountMin : (discountMin + '-' + discountMax)
					}
				}else if(promotionMethod == 4){
					// 设置的值是活动价
					specifiedPrice = settings

					discountMin = item.ProductPrice==0?0:(settings/item.ProductPrice*10).toFixed(1)
					discountMax = item.ProductPrice==0?0:(settings/item.ProductMaxPrice*10).toFixed(1)

					discountPriceMin = (item.ProductPrice-settings).toFixed(2)
					discountPriceMax = (item.ProductMaxPrice-settings).toFixed(2)
					
					if(item.ProductPrice == item.ProductMaxPrice){
						discountPrice = discountPriceMin
						discount  = discountMin
					}else{
						discountPrice  = (discountPriceMin==specifiedPriceMax)?discountPriceMin:(discountPriceMin + '-' + discountPriceMax)
						discount  = (discountMin==discountMax) ? discountMin : (discountMin + '-' + discountMax)
					}
				}
				let result = {
					Discount : discount,
					DiscountPrice : discountPrice,
					SpecifiedPrice : specifiedPrice,
				}
				return result
			},
			submitForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						alert('submit!');
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			resetForm(formName) {
				this.$refs[formName].resetFields();
			},
			commonDiscountClick() {
				this.currentType = 0
				this.commonDiscount = ''
				this.isShowEdit = true
			},
			commonDiscountPirceClick() {
				this.commonDiscountPrice = ''
				this.currentType = 1
				this.isShowEdit = true
			},
			commonSpecifiedPriceClick() {
				this.commonSpecifiedPrice = ''
				this.currentType = 2
				this.isShowEdit = true
			},
			editCancel() {
				this.isShowEdit = false
				this.currentType = null
			},
			choseActive() {
				if ((this.ruleForm.StartTime == '' || this.ruleForm.StartTime == null) || (this.ruleForm.EndTime == '' || this.ruleForm
						.EndTime == null)) {
					this.$message({showClose: true,
						type: 'error',
						message: '请先完善活动时间'
					})
				} else {
					this.addProductDialogShow = true;
					this.timeData = {
						StartTime: this.ruleForm.StartTime,
						EndTime: this.ruleForm.EndTime
					}
				}
			},

			getSelectList(data) {
				this.productSearchKey = ''
				this.addProductDialogShow = false
				if (this.selectedList.length) {
					let temp = this.selectedList.map(item => item.Id)
					let resArr = data.filter(item => !temp.includes(item.Id))
					resArr = resArr.map(t => {
						return {
							...t,
							Discount: '',
							DiscountPrice: '',
							SpecifiedPrice:'',
							promotionMethod:1,
						}
					})
					resArr.forEach(t => {
						this.selectedList.push(t)
					})
				} else {
					data = data.map(t => {
						return {
							...t,
							Discount: '',
							DiscountPrice: '',
							SpecifiedPrice:'',
							promotionMethod:1
						}
					})
					this.selectedList = data
				}
				this.handleFilterSelectes()
			},
			deleteChose(val) {
				this.selectedList = this.selectedList.filter(t => {
					if (t.Id != val.Id) {
						return t
					}
				})
				this.selectedFilterList = this.selectedFilterList.filter(t => {
					if (t.Id != val.Id) {
						return t
					}
				})
				if ((this.selectedFilterList.length) % this.page.size == 0 && this.page.current > 1) {
					this.page.current -= 1;
				}

				this.paginationPro()	
				this.$nextTick(()=>{
					this.$refs.ruleForm.validate();
				})
			},
			// 商品弹窗切换显示条数
			productSizeChange(val) {
				this.page.size = val;
				this.paginationPro()
				this.$nextTick(()=>{
					this.$refs.ruleForm.validate();
				})
			},
			productCurrentChange(val) {
				this.page.current = val;
				this.paginationPro()
				this.$nextTick(()=>{
					this.$refs.ruleForm.validate();
				})
			},
			changeDiscountSetType(){
				this.handleFilterSelectes()
				this.$nextTick(()=>{
					this.$refs.ruleForm.validate();
				})
			},
			handleFilterSelectes(){
				this.page.current = 1
				this.selectedFilterList = []
				this.selectedList.filter(t=>{
					if((t.Name.indexOf(this.productSearchKey)!=-1)||(t.ProductNo && (t.ProductNo.indexOf(this.productSearchKey)!=-1))){
						if(this.discountSetType){

							if(t.promotionMethod == 1){
								this.selectedFilterList.push(t)
							}else if(t.promotionMethod == 2){
								if(t.ProductPrice!=0&&(Number(t.Discount)<0||Number(t.Discount)==0||t.Discount==''||t.Discount==null)){
									this.selectedFilterList.push(t)
								}
							}else if(t.promotionMethod == 3){
								if(t.ProductPrice!=0&&(t.ProductPrice==t.DiscountPrice||t.DiscountPrice>t.ProductPrice||t.DiscountPrice==0||t.DiscountPrice<0)){
									this.selectedFilterList.push(t)
								}
							}else if(t.promotionMethod == 4){
								if(t.ProductPrice!=0&&(t.ProductPrice==t.SpecifiedPrice||t.SpecifiedPrice>t.ProductPrice||t.SpecifiedPrice==0||t.SpecifiedPrice<0)){
									this.selectedFilterList.push(t)
								}
							}

						}else{
							this.selectedFilterList.push(t)
						}
					}
				})
				this.paginationPro()
			},
			//商品分页
			paginationPro() {
				this.page.total = this.selectedFilterList.length;
				this.installationLength = 0
				this.computedInstallationLength()
				let start = (this.page.current - 1) * this.page.size
				let end = start + this.page.size
				let pagination = this.selectedFilterList.slice(start, end);
				this.ruleForm.ProductList = pagination
				this.$forceUpdate()
			},
			// 计算商品折扣设置错误的数量
			computedInstallationLength(){
				this.installationLength = 0
				this.selectedList.forEach(t=>{
					if(t.promotionMethod == 1){
						this.installationLength += 1
					}else if(t.promotionMethod == 2){
						if(t.ProductPrice!=0&&(Number(t.Discount)<0||Number(t.Discount)==0||t.Discount==''||t.Discount==null)){
							this.installationLength += 1
						}
					}else if(t.promotionMethod == 3){
						if(t.ProductPrice!=0&&(t.ProductPrice==t.DiscountPrice||t.DiscountPrice>t.ProductPrice||t.DiscountPrice==0||t.DiscountPrice<0)){
							this.installationLength += 1
						}
					}else if(t.promotionMethod == 4){
						if(t.ProductPrice!=0&&(t.ProductPrice==t.SpecifiedPrice||t.SpecifiedPrice>t.ProductPrice||t.SpecifiedPrice==0||t.SpecifiedPrice<0)){
							this.installationLength += 1
						}
					}
				})
			},
			handleCancel() {
				this.$router.back();
			},

			saveBtn() {
				this.$refs['ruleForm'].validate((valid) => {
					if(this.installationLength){
						this.$common.completeFormInformation('配置错误','is-error-button')
						this.discountSetType = true
						this.page.current = 1
						this.handleFilterSelectes()
						this.$nextTick(()=>{
							this.$refs.ruleForm.validate();
						})
						return
					}
					if (valid) {
						if(!this.selectedList.length){
							this.$common.completeFormInformation('请选择活动商品','is-error-button')
							return false
						}
						this.sureSave();
					} else {
						this.$common.completeFormInformation('请完善活动信息','is-error')
						return false;
					}
				});
				
			},
			async sureSave() {
				this.loading = true
				try {
					let TimeLimitDiscountProductList = this.selectedList.map(t => {
						let Discount = 0
						let DiscountPrice = 0
						let SpecifiedPrice = 0
						if(t.promotionMethod==2){
								Discount=t.Discount
								DiscountPrice=0
								SpecifiedPrice=0
						}else if(t.promotionMethod==3){
								Discount=0
								DiscountPrice=t.DiscountPrice
								SpecifiedPrice=0
						}else if(t.promotionMethod==4){
								Discount=0
								DiscountPrice=0
								SpecifiedPrice=t.SpecifiedPrice
						}
						return {
							ProductId: t.Id,
							Discount: Discount,
							DiscountPrice:DiscountPrice,
							SpecifiedPrice:SpecifiedPrice,
						}
					})
					const params = {
						ActivityFullId: this.activityId,
						Name: this.ruleForm.activeName,
						StartTime: this.ruleForm.StartTime,
						EndTime: this.ruleForm.EndTime,
						LimitType: this.ruleForm.radioLimit,
						Quota: this.ruleForm.limitNumber,
						IsSuperpositionCoupon:this.ruleForm.IsSuperpositionCoupon,
						ActivityFlag: this.ruleForm.activeTag,
						TimeLimitDiscountProductList: TimeLimitDiscountProductList,
						ActivityLimitType:this.ruleForm.ActivityLimitType,
						ActivityQuota:this.ruleForm.ActivityQuota,
						ActivityPreheatType:this.ruleForm.ActivityPreheatType,
						ActivityPreheatHours:this.ruleForm.ActivityPreheatType==2?this.ruleForm.ActivityPreheatHours:''
					}
					
					const res = await timelimitdiscountedit(params);

					if (res.IsSuccess) {
						this.$message({
							type:'success',
							showClose:true,
							message:'操作成功'
						})
						if(this.$route.query.copy||!this.$route.query.id){
							this.showtype = {
								issuccess:true,
								id:res.Result.Id,
								// type:this.$route.query.activeType,
								suctype:3,
							}
						}else{
							this.$router.push({
								path:'/market/limitedDiscount'
							})
						}
					} else if (res.Result && res.Result.length) {
						this.conflictVisible = true;
						this.conflictList = res.Result;
					}
				} catch (e) {
					this.loading = false
				} finally {
					this.loading = false
				}
			},
			async handleShowTooltip(val){
				this.tooltipTableName = val.Name
				this.tooltipTableVisible=true
				try{
					this.tooltipLoading = true
					let result = await apiList.productSpecGet({
						ProductId:val.Id
					})
					this.tooltipTableData = result.Result.ProductSpecList.map(t=>{
						
						let ActivePrice = 0
						if(val.promotionMethod==2){
							ActivePrice = (val.Discount*t.Price/10).toFixed(2)
						}else if(val.promotionMethod==3){
							ActivePrice = (t.Price-val.DiscountPrice).toFixed(2)
						}else if(val.promotionMethod==4){
							ActivePrice = val.SpecifiedPrice
						}
						return {
							...t,
							ActivePrice:ActivePrice,
							SpecTitle:result.Result.SpecTitle,
							SpecTitle2: result.Result.SpecTitle2
						}
					})
				} catch(e){
					console.log('e',e)
				} finally{
					this.tooltipLoading = false
				}
			}
		},

	}
</script>

<style lang="less" scoped>
	input[type=number]::-webkit-inner-spin-button {
		-webkit-appearance: none;
	}
	.discount-container{
		.discount-item{
			display:inline-block;
		}
	}
	.discount-item{
		::v-deep .el-input__inner{
			color:#909399 !important;
		}
	}

	.activepromotionMethod{
		::v-deep .el-input__inner{
			color:#333 !important;
		}
	}

	.el-form-date-pick {
		display: inline-block;
	}

	.footer {
		box-shadow: 13px 1px 6px #999;
		line-height: 80px;
		background-color: #fff;
		position: fixed;
		bottom: 0px;
		width: 100%;
		text-align: center;
		z-index: 999;
	}
</style>
